import React, {
  FC,
  FocusEvent,
  InputHTMLAttributes,
  Ref,
  useState,
} from 'react';
import styled from 'styled-components';
import Show from 'assets/icons/show-eye.svg';
import Hide from 'assets/icons/hide-eye.svg';

interface TextInputProps {
  label?: string;
  error?: string;
  inputRef?: Ref<HTMLInputElement>;
}

interface InputProps {
  focused: boolean;
  hasError: boolean;
  required?: boolean;
}

const Container = styled.div`
  width: 100%;
`;

const InputContainer = styled.div`
  position: relative;
  padding-top: 0.5rem;
`;

const Label = styled.label`
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;

const Required = styled.label`
  font-family: Inter;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.red100};
`;

const Error = styled.div`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.14331rem;
  color: rgb(255, 109, 92);
  padding-top: 0.5rem;
`;

const Input = styled.input<InputProps>`
  outline: none;
  width: 100%;
  color: #000;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
  height: 3rem;
  padding: 0.875rem 0.8125rem;
  border-radius: 0.625rem;
  border: 1px solid #f5f5f5;
  background: #fff;
  ::placeholder {
    color: #777;
  }
`;

const ToggleButton = styled.button`
  position: absolute;
  right: 10px;
  top: 61%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
`;

export const PasswordInput: FC<
  TextInputProps & InputHTMLAttributes<HTMLInputElement>
> = ({
  type,
  inputRef,
  disabled,
  label,
  error,
  required,
  onFocus,
  onBlur,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const toggleFocus = () => {
    setFocused(!focused);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    toggleFocus();
    onFocus?.(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    toggleFocus();
    onBlur?.(e);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container>
      {!!label && <Label>{label}</Label>}
      <InputContainer>
        <Input
          ref={inputRef}
          type={showPassword ? 'text' : 'password'}
          disabled={disabled}
          placeholder="Enter here"
          focused={focused}
          hasError={!!error}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...props}
        />
        <ToggleButton type="button" onClick={togglePasswordVisibility}>
          {showPassword ? <Hide /> : <Show />}
        </ToggleButton>
      </InputContainer>
      {!!error && <Error>{error}</Error>}
    </Container>
  );
};
